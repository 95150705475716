<template>
    <div>
        <h2 class="h5 font-weight-bold text-center mb-4">Semaine {{ weekNumber }}</h2>
        <div class="table-responsive">
            <table class="table table-bordered overflow-auto">
                <tbody>
                    <template v-for="(line, index) in timetable">
                        <time-sheet-line-component
                            :key="index"
                            :line="line"
                            @dropLine="dropLine(index)"
                            @duplicateToNextDay="duplicateToNextDay(index)"
                            @sortTimetable="sortTimetable()"
                            :disabled="disabled"
                            :work-leave-types="workLeaveTypes"
                            :work-leave-type="workLeaveType"
                            @updateWorkLeaveCode="updateWorkLeaveCode(index, $event)"
                        ></time-sheet-line-component>
                    </template>
                    <div class="input-group d-flex mt-3 col-12 p-0 justify-content-between px-md-0 px-lg-3 px-xl-0">
                        <div class="col-12 col-md-7 col-lg-12 col-xl-7">
                            <div class="m-3" v-if="isFlexibility">Vous pouvez prolonger le contrat jusqu'au <b>{{dateFinSouplesse}}</b>
                            </div>
                        </div>
                        <div class="form-check m-3 mx-md-0 col-12 col-md-5 col-lg-12 col-xl-5 d-flex justify-content-md-end justify-content-lg-start justify-content-xl-end" v-if="isEndOfMission">
                            <div>
                                <input id="is-flexibility-active" type="checkbox" name="flexibility_activated" class="form-check-input pl-0" v-model="isFlexibility" :form="getFormName()" value="1">
                                <label for="is-flexibility-active" class="form-check-label">Activer la souplesse positive<i class="fa-solid fa-info-circle text-center" data-toggle="tooltip" title="La souplesse permet de modifier le terme d’une mission intérim, selon des conditions bien précises. Activer la souplesse positive vous permet de prolonger le contrat sans faire d'avenant."></i></label>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex align-items-end flex-column" v-if="timetable.length">
                        <div class="input-group d-flex flex-column mt-3 align-items-md-end align-items-lg-start align-items-xl-end col-12 col-md-10 col-lg-12 col-xl-10 p-0">
                            <div class="form-check m-3">
                                <input id="is-work-leave-all-week" type="checkbox" class="form-check-input" v-model="isWorkLeaveAllWeek">
                                <label for="is-work-leave-all-week" class="form-check-label">Absent toute la semaine</label>
                            </div>
                            <div v-if="isWorkLeaveAllWeek" class="d-flex flex-column col-12 col-md-6 mb-2">
                                <label>Absence :</label>
                                    <select
                                    class="form-control js-hide-neighbours"
                                    :disabled="disabled"
                                    :reset-on-options-change='true'
                                    v-model="workLeaveType"
                                >
                                    <option value=""></option>
                                    <template v-for="workLeaveType in workLeaveTypes">
                                        <option v-text="workLeaveType.name" :value="workLeaveType.code"></option>
                                    </template>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div v-if="timetable.length == 0">
                        <p class="text-center" colspan="12">Aucun horaire renseigné </p>
                    </div>
                    <div>
                        <div class="text-right m-3" colspan="12"><strong>Total : <span v-text="timeSheetDuration.hours" />h<span v-text="String(timeSheetDuration.minutes).padStart(2, '0')" /></strong></div>
                    </div>
                </tbody>
            </table>
            <input :form="getFormName()" type="hidden" :name="getInputName()" :value="JSON.stringify(timetable)">
        </div>
    </div>
</template>

<script>
    import { DateTime, Duration, Info, Interval } from 'luxon';
    import TimeSheetLineComponent from './TimeSheetLine.vue';
    import { TimeSheetLine } from '@models';
    import { range } from 'lodash';

    export default {
        name: "TimeSheet",
        components: {
            TimeSheetLineComponent
        },
        props: {
            initTimetable: {
                type: Array,
                default() {
                    return [];
                },
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            formName: {
                type: String,
                default: 'TimeSheetForm',
            },
            inputName: {
                type: String,
                default: 'time_sheet_lines',
            },
            quickIntent: {
                type: Object,
                default: '',
            },
            timeSheet: {
                type: Object,
                default: '',
            },
            weekNumber: {
                type: Number,
                default: '00',
            },
            workLeaveTypes: {
                type: Array,
                default() {
                    return [];
                },
            },
            workLeaveType: {
                type: String,
                default:'',
            },
        },
        data() {
            return {
                timetable: [],
                errors: {},
                isWorkLeaveAllWeek: false,
                isFlexibility: false,
            };
        },
        created() {
            this.timetable = TimeSheetLine.make(
                this.initTimetable.map((line) => {
                    return {
                        id: line.id,
                        selected: false,
                        from: DateTime.fromISO(line.from),
                        to: DateTime.fromISO(line.to),
                        differ: line.differ,
                        deleted: line.deleted,
                        code: line.code,
                    };
                })
            );
        },
        computed: {
            dateFinSouplesse(){
                const date = DateTime.fromISO(this.quickIntent.flexibility_period_end_at);
                return date.setLocale('fr').toFormat('cccc dd MMMM');
            },
            selectedLines() {
                return this.timetable.filter((value, index) => { return value.selected});
            },
            isEndOfMission() {
                console.log(this.quickIntent.end_at);
                if (DateTime.fromISO(this.quickIntent.end_at).weekNumber == this.weekNumber
                    && DateTime.fromISO(this.quickIntent.end_at).month == DateTime.fromISO(this.timeSheet.end_at).month
                ) {
                    return true
                }
                return false
            },
            timeSheetDuration() {
                let _this = this;
                let duration = this.timetable
                    .filter((value, index) => { return value.deleted === null})
                    .filter((value, index) => { return value.code === null})
                    .reduce(function (carry, line) {
                        return carry.plus(line.duration());
                    }, Duration.fromObject({}))
                    .shiftTo('hours', 'minutes')
                    .toObject()
                ;

                return {
                    'hours': duration.hours,
                    'minutes': String(duration.minutes).padStart(2, '0')
                };
            },
            weekdays() {
                return range(1, 8)
                    .reduce((acc, element) => {
                        return {
                            ...acc,
                            [element]: Info.weekdays('long')[element - 1]
                        }
                    }, {})
                ;
            }
        },
        methods: {
            addToTimetable(line, index = null) {
                this.timetable.push(
                    new TimeSheetLine({
                        id: null,
                        from: line.from,
                        to: line.to,
                        selected: false,
                        differ: null,
                        deleted: null,
                        code: null,
                    })
                );
            },
            dropLine(index) {
                this.timetable.splice(index, 1);
                this.sortTimetable();
            },
            dropLines() {
                this.timetable = this.timetable.filter((value, index) => {
                    return !value.selected;
                })

                this.sortTimetable();
            },
            duplicateToNextDay(index) {
                let initialLine = this.timetable[index];

                this.addToTimetable({
                    id: null,
                    from: initialLine.from.plus({ day: 1}),
                    to: initialLine.to.plus({ day: 1}),
                    selected: false,
                    differ: null,
                    deleted: null,
                    code: null,
                });
            },
            getInputName() {
                return this.inputName;
            },
            getFormName() {
                return this.formName;
            },
            sortTimetable() {
                this.timetable.sort((a, b) => {
                    return a.from - b.from;
                })
            },
            updateWorkLeaveCode(index) {
              this.timetable[index].code = this.workLeaveType;
            }
        },
    }
</script>

<style lang="scss">
    .w-10 {
        width: 10%;
    }
</style>
